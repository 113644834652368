@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

.select-scrollbar-visible {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.select-scrollbar-visible::-webkit-scrollbar {
  width: 8px;
  display: none; /* Chrome, Safari and Opera */
}

.select-scrollbar-visible:hover {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

.select-scrollbar-visible:hover::-webkit-scrollbar {
  display: block; /* Chrome, Safari and Opera */
}

.select-scrollbar-visible::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.select-scrollbar-visible::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 10px; /* Adjust this value to your liking */
}

.select-scrollbar-visible::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraLight.otf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraLightItalic.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Light.otf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-LightItalic.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Regular.otf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-RegularItalic.otf');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Medium.otf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-MediumItalic.otf');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-SemiBold.otf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-SemiBoldItalic.otf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Bold.otf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-BoldItalic.otf');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraBold.otf');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraBoldItalic.otf');
  font-style: italic;
  font-weight: 800;
}

body {
  margin: 0;
  font-family: Metropolis;
  height: 100%;
}

thead {
  border-collapse: separate;
  border-spacing: 0;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
