.otpInput {
  text-align: center;
  height: 48px;
  width: 48px;
  margin: 0 0.5rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.input:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
